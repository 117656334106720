import MessageController from "../step/message_controller"

import { commaSeperatedListWithAndConjunction } from '../models/sentence'

export default class extends MessageController {
  static values = {
    name: String,
    value: String,
    id: String,
    kind: String,
  }

  static targets = [
    "title",
    "description",
    "submit",
    "openModal",
    "propertyInput",
    "valuesInput",
  ]

  initialize() {
    this.name = this.nameValue
    this.value = this.valueValue
    this.display = this.descriptionTarget.innerText

    this.paneSnapshot = this.paneTarget.cloneNode(true)
    super.initialize()
  }

  connect() {
    if((this.withinAnswer || this.afterMessage) && this.persistedValue && !['date', 'birthday'].includes(this.kindValue)) {
      this.dispatch('reply:show', {
        target: this.paneTarget,
      })
    }

    super.connect()
  }

  togglePane() {
    if (window.paneId) {
     return window.dispatchEvent(
        new CustomEvent("pane:alert", {detail: this.element.id})
      )
    }

    this.paneTarget.classList.toggle("-right-full")
    this.paneTarget.classList.toggle("right-0")

    this.triggerTarget.classList.toggle("halo--active")

    if(this.paneIsInvisible) return

    this.dispatch('focus', {
      target: this.paneTarget,
    })

    if(!this.persistedValue) {
      this.delayed(() => {
        this.dispatch('show', {
          target: this.paneTarget.querySelector('[data-automation--property-value-target="propertiesPopover"]'),
        })
      }, 300)
    }
  }

  saveValue({ detail }) {
    this.name = detail.name
    this.value = detail.value
    this.id = detail.id
    this.display = detail.display || detail.value

    if(!this.withinAnswer && !this.afterMessage) return

    if(detail.text || detail.number) {
      this.dispatch('reply:show', {
        target: this.paneTarget,
      })

      if(!detail.value) {
        this.dispatch('reply', {
          target: this.paneTarget,
        })

        this.value = '{reply}'
      }
    } else {
      this.dispatch('reply:hide', {
        target: this.paneTarget,
      })
    }
  }

  save() {
    window.paneId = null

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.titleTarget.textContent = t.automation.properties.property.title.set.replace('%{property}', this.name)
    this.descriptionTarget.textContent = commaSeperatedListWithAndConjunction(this.display.split(','))

    this.paneSnapshot = this.paneTarget.cloneNode(true)

    this.persistedValue = true

    this.nameValue = this.name
    this.valueValue = this.value
    this.idValue = this.id

    this.propertyInputTarget.value = this.idValue
    this.valuesInputTarget.value = this.valueValue
  }

  onClickOutside({ target }) {
    if(!this.persistedValue) {
     return this.remove()
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")
  }

  abortChanges() {
    if(!this.persistedValue) {
      return this.remove()
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.name = this.nameValue
    this.value = this.valueValue

    this.delayed(() => {
      this.paneTarget.replaceWith(this.paneSnapshot.cloneNode(true))

      this.paneTarget.querySelector('input.form-control').remove()
    }, 300)
  }

  remove() {
    this.dropzone.remove()

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false

      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.nextTick(() => {
      this.paneTarget.remove()
      this.element.remove()
    }, 300)
  }

  get paneIsInvisible() {
    return this.paneTarget.classList.contains("-right-full")
  }

  get paneIsVisible() {
    return this.paneTarget.classList.contains("right-0")
  }

  get afterMessage() {
    return document.getElementById(this.parentTarget.value)?.dataset?.kind === 'message'
  }

  get paneTarget() {
    return document.getElementById(`${this.element.id}_pane`)
  }
}
