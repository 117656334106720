import GroupController from '../filter/group_controller'

export default class extends GroupController {
  static values = {
    answers: Array,
  }

  static targets = [
    'submit',
    'limitNotice',
    'details',
    'openModal',
    'closeModal',
    'gracePeriodInput',
    'removeButton',
  ]

  connect() {
    this.detailsTarget.addEventListener('toggle', ({ target }) => {
      if(target.open) {
        const lastItem = this.itemTargets[this.itemTargets.length - 1]

        const input = lastItem.querySelector('input')
        this.focus(input, { moveCursorToEnd: true })
      }
    })

    super.connect()
  }

  update({ detail: answersWithIds }) {
    if(!Array.isArray(answersWithIds)) {
      return
    }

    this.itemTargets.forEach((item, index) => {
      item.setAttribute('data-id', answersWithIds[index].id)
    })

    this.answersValue = answersWithIds
  }

  onInputEnter({ target }) {
    if(!target.value || this.limitReached) return

    const parent = target.closest('[data-automation--answers-target="item"]')
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    if(parent === lastItem) {
      this.addNewItem()
    }
  }

  onAnswerChange({ detail, srcElement }) {
    const { id, ...answer } = detail
    const elementIndex = this.itemTargets.indexOf(srcElement)

    if(this.answersValue.length === 0) {
      return this.answersValue = [
        {
          id,
          ...answer
        }
      ]
    }

    this.answersValue = this.answersValue.map((value, index) => {
      // when answer is new and has not been saved yet, the ID is null
      // when the id is null, we compare by the index of the element.
      if((id && value.id === id) || index === elementIndex) {
        return {
          id,
          ...answer
        }
      } else {
        return value
      }
    })
  }

  onComposeInitialization({ detail: composeSnapshot }) {
    this.nextTick(() => {
      this.dispatch('compose:initialized', {
        detail: composeSnapshot,
        target: this.stepElement,
      })
    }, 0)
  }

  onComposeChange({ detail: composeSnapshot }) {
    this.nextTick(() => {
      this.dispatch('compose:changed', {
        detail: composeSnapshot,
        target: this.stepElement,
      })
    })
  }

  onClickOutside({ target }) {
    if(this.paneIsInvisible || this.element.contains(target) || target.closest('dialog')) return

    if(this.hasUnsavedChanges) {
      return this.openModalTarget.click()
    }

    this.abortChanges()
  }

  abortChanges() {
    this.dispatch('abort', {
      target: this.stepElement,
    })
  }

  onGracePeriodIntervalChange({ detail }) {
    this.dispatch('gracePeriodInterval:changed', {
      target: this.stepElement,
      detail,
    })

    this.focus(this.gracePeriodInputTarget, { moveCursorToEnd: true })
  }

  onModalClose() {
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    const input = lastItem.querySelector('input')
    this.focus(input, { moveCursorToEnd: true })
  }

  addNewItem() {
    if(this.limitReached) return

    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const newItem = this.templateTarget.cloneNode(true)

    newItem.querySelector('label').textContent = t.automation.answer.label.replace("%{count}", this.itemTargets.length + 1)

    newItem.setAttribute('data-automation--answers-target', 'item')
    newItem.setAttribute('data-controller', 'automation--answer-field')

    newItem.classList.remove('hidden')
    newItem.querySelectorAll('input, select').forEach(this.enable)

    this.listTarget.insertBefore(newItem, this.templateTarget)

    this.nextTick(() => {
      this.focus(newItem.querySelector('input'))
    })

    lastItem.querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.remove('hidden')

    this.answersValue = [
      ...this.answersValue,
      {
        body: "",
        query: 'equals',
        text: t.applied_filters.query.equals,
      }
    ]
  }

  removeItem({ currentTarget }) {
    const item = currentTarget.closest('[data-automation--answers-target="item"]')

    this.answersValue = this.answersValue.filter((_, index) => {
      return index !== this.itemTargets.indexOf(item)
    })

    item.remove()
  }

  itemTargetDisconnected() {
    super.itemTargetDisconnected()

    this.itemTargets.forEach((item, index) => {
      item.querySelector('label').textContent = t.automation.answer.label.replace("%{count}", index + 1)
    })

    this.hide(this.limitNoticeTarget)
  }

  itemTargetConnected() {
    if(this.limitReached) {
      this.hide(this.orValueButtonTarget)
      this.show(this.limitNoticeTarget)
    } else {
      this.hide(this.limitNoticeTarget)
    }

    if(this.itemTargets.length === 1) {
      this.itemTargets[0].querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.add('hidden')
    } else {
      this.itemTargets.forEach(item => {
        item.querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.remove('hidden')
      })
    }
  }

  answersValueChanged() {
    const valid = !(this.answersValue.length === 0 || (this.answersValue.length === 1 && this.answersValue[0].body === ""))

    this.nextTick(() => {
      this.dispatch('validation', {
        target: this.stepElement,
        detail: {
          valid,
          answers: this.answersValue,
        }
      })
    }, 0)
  }

  save() {
    this.stepController.save()
    this.show(this.removeButtonTarget)
  }

  remove() {
    this.stepController.remove()
  }

  get stepElement() {
    return document.getElementById(this.element.id.replace('_pane', ''))
  }

  get hasUnsavedChanges() {
    return this.stepController.hasUnsavedChanges
  }

  get stepController() {
    return  this.application.getControllerForElementAndIdentifier(this.stepElement, 'automation--question')
  }

  get paneIsInvisible() {
    return this.element.classList.contains("-right-full")
  }

  get paneIsVisible() {
    return this.element.classList.contains("right-0")
  }
}
