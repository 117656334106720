import ApplicationController from '@/controllers/application_controller'

export default class extends ApplicationController {
  static values = {
    object: Object,
  }

  static targets = [
    'primaryColorPicker',
    'secondaryColorPicker',
    'input',
    'preview',
    'logo',
  ]

  connect() {
    this.changeForegroundColor()
    this.changeBackgroundColor()

    super.connect()
  }

  onColorChange(event) {
    const target = event.srcElement.closest('[data-webchat--personalization-target]')

    if(target === this.primaryColorPickerTarget) {
      this.objectValue = {
        ...this.objectValue,
        primary_color: event.detail.rgba,
      }

      this.changeForegroundColor()
    } else {
      this.objectValue = {
        ...this.objectValue,
        secondary_color: event.detail.rgba,
      }

      this.changeBackgroundColor()
    }
  }

  changeForegroundColor() {
    this.previewTarget.style.setProperty('--webchat-primary-color', this.objectValue.primary_color)

    let rgba = this.objectValue.primary_color
    this.previewTarget.style.setProperty('--webchat-message-background-color', this.changeAlpha(rgba, 0.3))
  }

  changeBackgroundColor() {
    this.previewTarget.style.setProperty('--webchat-secondary-color', this.objectValue.secondary_color)
  }

  mimicMessageSending(e) {
    this.inputTarget.value = ""
  }

  onTypographyChange({ currentTarget }) {
    const { optionValue: fontFamily } = currentTarget.dataset
    this.previewTarget.style.fontFamily = fontFamily
  }

  changeAlpha(rgba, newAlpha) {
    return rgba.replace(/rgba?\((\d+), (\d+), (\d+),? (\d*\.?\d+)?\)/, (match, r, g, b) => {
      return `rgba(${r}, ${g}, ${b}, ${newAlpha})`;
    });
  }
}
