import { get } from '@rails/request.js'
import PopoverController from '../ui/popover_controller'

export default class extends PopoverController {
  static values = {
    url: String,
  }

  static targets = [
    'input',
    'item',
    'pill',
  ]

  debounceAndSearch({ target }) {
    this.debounce(() => {
      this.search(target.value)
    }, 300)
  }

  async search(value) {
    if(!value) {
      return this.hide()
    }

    await get(this.urlValue, {
      responseKind: 'turbo-stream',
      query: {
        element: this.element.id,
        search: value,
        ignore: this.pillTargets.map(pill => pill.dataset.name).join(',')
      }
    })

    this.show()
  }

  add({ currentTarget }) {
    const pill = currentTarget.querySelector('[data-pill]').cloneNode(true)

    if(this.pillTargets.filter(p => p.dataset.id).find(p => p.dataset.id === pill.dataset.id)) {
      this.inputTarget.value = ''
      super.focus(this.inputTarget)

      currentTarget.remove()

      if(this.itemTargets.length === 0) {
        this.hide()
      }

      return
    }

    pill.removeAttribute('data-pill')
    pill.classList.remove('hidden')

    pill.setAttribute('data-automation--list-search-target', 'pill')

    if(this.pillTargets.length === 0) {
      this.triggerTarget.prepend(pill)
    } else {
      this.pillTargets[this.pillTargets.length - 1].insertAdjacentElement('afterend', pill)
    }

    currentTarget.remove()

    if(this.itemTargets.length === 0) {
      this.hide()
    }

    this.inputTarget.value = ''
    super.focus(this.inputTarget)

    this.listIds = this.pillTargets.map(pill => pill.dataset.name)

    this.dispatch('set', {
      detail: this.listIds,
    })
  }

  submitFirstItem() {
    if(this.itemTargets.length > 0) {
      this.add({ currentTarget: this.itemTargets[0] })
    }
  }

  removeLastItem() {
    if(this.inputTarget.value || this.pillTargets.length === 0) return
    this.pillTargets[this.pillTargets.length - 1].remove()

    if(this.itemTargets.length === 0) {
      this.hide()
    }
  }

  remove({ currentTarget }) {
    currentTarget.closest('[data-automation--list-search-target="pill"]').remove()
    super.focus(this.inputTarget)
  }

  focus() {
    super.focus(this.inputTarget)
  }

  clear() {
    this.pillTargets.forEach(pill => pill.remove())
    this.inputTarget.value = ''

    this.hide()
  }

  pillTargetDisconnected() {
    this.listIds = this.pillTargets.map(pill => pill.dataset.name)

    this.dispatch('set', {
      detail: this.listIds,
    })
  }
}
