import { post, destroy } from "@rails/request.js"

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    url: String,
    seen: Boolean,
    id: String,
    creator: String,
    address: String,
    body: String,
    location: Boolean,
    type: String,
    multiple: Boolean,
    caption: Boolean,
    originalId: String,
    hasAttachment: Boolean,
    conversationElementId: String,
    reactionUrl: String,
    reactionsUrl: String,
  }

  static targets = ["toggleButton", "location", "body", "kebabIcon", "emoji", "reactionContainer"]

  initialize() {
    this.messageViewedObserver = this.messageViewedObserver.bind(this)
    this.observer = new IntersectionObserver(this.messageViewedObserver, {
      root: null,
      threshold: 0.1,
    })
  }

  connect() {
    if(this.hasLocationTarget) {
      const link = this.locationTarget.querySelector("a")
      const image = this.locationTarget.querySelector("img")

      link.href = link.href.replace(":locale", this.userLocale)
      image.src = image.src.replace(":locale", this.userLocale)

      this.delayed(() => {
        this.show(this.locationTarget)
      }, 1000)
    }

    this.observer.observe(this.element)

    this.element.querySelectorAll('a').forEach(link => {
      link.target = '_blank'
    })
  }

  reply() {
    if(this.captionValue) {
      this.dispatch("reply", {
        target: document.getElementById(this.originalIdValue),
      })
    } else {
      this.dispatch(
        "reply",
        {
          target: document.querySelector("x-reply-to-container"),
          detail: {
            id: this.idValue,
            body: this.hasBodyTarget ? this.bodyTarget.innerHTML : this.bodyValue || this.addressValue,
            creator: this.creatorValue,
            attachedLocation: this.locationValue,
            type: this.typeValue,
            isMultiple: this.multipleValue,
            element: this.element
          }
        }
      )
    }
  }

  displayTimestamps({ target }) {
    this.toggleButtonTarget.click()
  }

  onDropdownToggle({ detail: visible }) {
    if(visible) {
      this.removeClass(this.kebabIconTarget, "invisible")
    } else {
      this.addClass(this.kebabIconTarget, "invisible")
    }
  }

  async messageViewedObserver(entry) {
    if (entry[0].isIntersecting && !this.seenValue && this.readable) {
      const response = await post(this.urlValue, { responseKind: "turbo-stream" })

      if(response.ok) {
        this.seenValue = true

        this.dispatch("seen", {
          target: document.getElementById(this.conversationElementIdValue),
        })

        this.dispatch("seen", {
          target: document.getElementById('inbox-link'),
        })
      }
    }
  }

  addReaction({ detail: reaction }) {
    const data = {
      emoji: reaction.native,
      metadata: {
        ...reaction,
      }
    }

    if(this.hasReaction(reaction.native)) {
      this.hide(this.reactionContainerTarget)
      return destroy(this.reactionUrlValue.replace(':id', reaction.native))
    }

    this.reactionContainerTarget.innerHTML = ""
    const span = document.createElement("span")
    span.innerHTML = reaction.native

    this.reactionContainerTarget.appendChild(span)
    this.show(this.reactionContainerTarget)

    post(this.reactionsUrlValue, { body: data })
  }

  emojiTargetConnected() {
    this.show(this.reactionContainerTarget)
  }

  emojiTargetDisconnected() {
    if(this.emojiTargets.length > 0) return
    this.hide(this.reactionContainerTarget)
  }

  hasReaction(native) {
    return this.reactionContainerTarget.firstElementChild?.innerText === native
  }

  get readable() {
    return this.urlValue
  }
}
