import { Controller } from "@hotwired/stimulus"
import iro from "@jaames/iro"

import { useColorPickerVisibilityControls } from "../mixins/useColorPickerVisibilityControls"

export default class extends Controller {
  static targets = ["picker", "preview", "colorPickerControls", "colorInput"]
  static values = {
    default: { type: String, default: "#000000" },
  }

  connect() {
    useColorPickerVisibilityControls(this, {
      pickerTarget: this.colorPickerControlsTarget,
    })

    this.dispatch("color:changed", {
      target: document.documentElement,
      detail: {
        color: this.defaultValue,
      },
    })
  }

  changeColor({ detail: newColor }) {
    this.colorInputTarget.value = newColor.hex

    this.previewTargets.forEach((previewTarget) => {
      previewTarget.style.backgroundColor = newColor.hex
    })

    this.dispatch("color:changed", {
      target: document.documentElement,
      detail: {
        color: newColor.hex,
      },
    })
  }

  // private

  onColorWheelChanged(color) {
    this.dispatch("color:changed", {
      target: document.documentElement,
      detail: {
        color: color.hexString,
      },
    })

    this.previewTargets.forEach((previewTarget) => {
      previewTarget.style.backgroundColor = color.hexString
    })
  }

  onColorInputChanged(color) {
    this.colorInputTarget.value = color.hexString.split("#")[1].toUpperCase()
  }
}
