import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static values = {
    limits: Object,
    count: Number,
    buttons: { type: Array, default: [] },
    buttonsCount: Object,
  }

  static targets = [
    'cancelButton',
    'triggerButton',
    'addButton',
    'listTemplate',
    'groupsContainer',
    'sectionTemplate',
    'section',
    'buttonInput',
    'addButtonPopover',
    'addButtonTooltip',
    'content',
    'limitTooltip',
  ]

  initialize() {
    this.buttonsCountValue = {
      url: 0,
      phone: 0,
      quick_reply: 0,
    }

    super.initialize()
  }

  connect() {
    this.buttonsValue.forEach(({ type, buttons }) => {
      this.buttonsCountValue = {
        ...this.buttonsCountValue,
        [type]: buttons.length,
      }
    })

    if(this.sectionTargets.length === 0) {
      this.insertNewList('quick_reply')
    }

    if(this.limitReached) {
      this.hide(this.contentTarget)
      this.show(this.limitTooltipTarget)
    }

    super.connect()
  }

  insertIntoExistingList(type) {
    const section = this.sectionTargets.find(section => section.dataset.type === type)
    const buttonInputComponent = section.querySelector('[data-controller*="compose--template--button"]').cloneNode(true)

    buttonInputComponent.setAttribute('data-compose--template--button-manager-target', 'buttonInput')
    buttonInputComponent.querySelectorAll('input').forEach(input => input.value = '')

    buttonInputComponent.classList.remove('hidden')

    section.querySelector('ul').appendChild(buttonInputComponent)
  }

  insertNewList(type, buttons = null) {
    const section = this.sectionTemplateTargets.find(template => template.dataset.type === type).cloneNode(true)

    section.setAttribute('data-compose--template--button-manager-target', 'section')
    section.classList.remove('hidden')

    const ul = section.querySelector('ul')

    if(!buttons) {
      const buttonInputComponent = section.querySelector('[data-controller*="compose--template--button"]')

      buttonInputComponent.setAttribute('data-compose--template--button-manager-target', 'buttonInput')
      buttonInputComponent.classList.remove('hidden')

      if(ul) {
        ul.appendChild(buttonInputComponent)
      } else {
        section.appendChild(buttonInputComponent)
      }

      return this.groupsContainerTarget.appendChild(section)
    }

    buttons.forEach(button => {
      const buttonInputComponent = section.querySelector('[data-controller*="compose--template--button"]').cloneNode(true)
      buttonInputComponent.setAttribute('data-compose--template--button-manager-target', 'buttonInput')

      const titleInput = buttonInputComponent.querySelector('input[data-kind="title"]')
      const valueInput = buttonInputComponent.querySelector('input[data-kind="value"]')

      if(titleInput) {
        titleInput.value = button.title
      }

      valueInput.value = button.value

      buttonInputComponent.classList.remove('hidden')

      if(ul) {
        ul.appendChild(buttonInputComponent)
      } else {
        section.appendChild(buttonInputComponent)
      }
    })

    this.groupsContainerTarget.appendChild(section)
  }

  insert({ currentTarget }) {
    const { type } = currentTarget.dataset

    if(this.sectionTargets.find(section => section.dataset.type === type)) {
      this.insertIntoExistingList(type)
    } else {
      this.insertNewList(type)
    }
  }

  save() {
    const object = this.sectionTargets.map(section => {
      const type = section.dataset.type

      return {
        type,
        buttons: Array
          .from(section.querySelectorAll('[data-controller*="compose--template--button"]'))
          .filter(button => button.hasAttribute('data-valid'))
          .map(button => this.application.getControllerForElementAndIdentifier(button, 'compose--template--button').toObject())
      }
    })

    const event = this.isEditing ? 'update' : 'insert'

    this.dispatch(event, {
      detail: object,
    })

    this.buttonsValue = object

    this.removeActiveClassOnTriggerButton()
    this.reset()

    if(this.limitReached) {
      this.hide(this.contentTarget)
      this.show(this.limitTooltipTarget)
    }
  }

  edit({ detail: object }) {
    const { type, groups, index } = object

    this.sectionTargets.forEach(section => section.remove())

    console.log(type, index)
    groups.forEach(({ type, buttons }) => {
      this.buttonsCountValue = {
        ...this.buttonsCountValue,
        [type]: 0,
      }

      this.insertNewList(type, buttons)
    })

    this.show(this.contentTarget)
    this.hide(this.limitTooltipTarget)

    this.addClass(this.triggerButtonTarget, 'icon-button--compose--tiger')

    this.nextTick(() => {
      this.dispatch('open', {
        target: this.contentTarget,
      })

      this.nextTick(() => {
        const buttonToFocus = this.buttonInputTargets.filter(buttonInput => buttonInput.dataset.type === type)[index]
        buttonToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' })


        this.dispatch('focus', {
          target: buttonToFocus,
        })
      })
    })

    this.isEditing = true
  }

  buttonInputTargetConnected(buttonInput) {
    const { type } = buttonInput.dataset

    this.dispatch('focus', {
      target: buttonInput,
    })

    if(this.buttonInputTargets.length === 1) {
      this.dispatch('remove:disallow', {
        target: buttonInput,
      })
    } else {
      this.buttonInputTargets.forEach(buttonInput => {
        this.dispatch('remove:allow', {
          target: buttonInput,
        })
      })
    }

    this.buttonsCountValue = {
      ...this.buttonsCountValue,
      [type]: (this.buttonsCountValue[type] || 0) + 1,
    }

    this.syncRemoveableSegments()

    if(this.limitReached) {
      this.hide(this.addButtonPopoverTarget)
      this.show(this.addButtonTooltipTarget)
    }

    if(this.buttonInputTargets.filter(buttonInput => buttonInput.dataset.type === 'url').length === 2) {
      this.addButtonTargets.find(button => button.dataset.type === 'url').classList.add('hidden')
    }

    buttonInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  buttonInputTargetDisconnected(buttonInput) {
    const { type } = buttonInput.dataset
    const section = this.sectionTargets.find(section => section.dataset.type === type)

    this.buttonsCountValue = {
      ...this.buttonsCountValue,
      [type]: this.buttonsCountValue[type] - 1,
    }

    if(this.buttonInputTargets.length === 1) {
      this.dispatch('remove:disallow', {
        target: this.buttonInputTargets[0],
      })
    }

    this.dispatch('focus', {
      target: this.buttonInputTargets[this.buttonInputTargets.length - 1],
    })

    if(type === 'url' || type === 'phone') {
      this.addButtonTargets.find(button => button.dataset.type === type).classList.remove('hidden')
    }

    this.show(this.addButtonPopoverTarget)
    this.hide(this.addButtonTooltipTarget)

    this.syncRemoveableSegments()
  }

  syncRemoveableSegments() {
    this.sectionTargets.filter(section => section.dataset.type !== 'phone').forEach((section) => {
      const buttons = section.querySelectorAll('[data-controller*="compose--template--button"][data-compose--template--button-manager-target="buttonInput"]')

      if(buttons.length > 1) {
        buttons.forEach(button => {
          this.dispatch('drag:allow', { target: button })
        })
      } else if(buttons.length === 0) {
        section.remove()
      } else {
        this.dispatch('drag:disallow', { target: buttons[0] })
      }
    })

    Object.entries(this.limitsValue).forEach(([type, limit]) => {
      if(this.buttonsCountValue[type] >= limit) {
        this.addButtonTargets.find(button => button.dataset.type === type).classList.add('hidden')
      } else {
        this.addButtonTargets.find(button => button.dataset.type === type)?.classList?.remove('hidden')
      }
    })

    if(Object.values(this.buttonsCountValue).reduce((acc, count) => acc + count, 0) >= 10) {
      this.hide(this.addButtonPopoverTarget)
      this.show(this.addButtonTooltipTarget)
    } else {
      this.show(this.addButtonPopoverTarget)
      this.hide(this.addButtonTooltipTarget)
    }
  }

  sectionTargetConnected() {
    this.sectionTargets.forEach((section, index) => {
      if(index === 0) {
        section.classList.remove('pt-4')
      } else {
        section.classList.add('pt-4')
      }
    })
  }

  sectionTargetDisconnected() {
    this.sectionTargets.forEach((section, index) => {
      if(index === 0) {
        section.classList.remove('pt-4')
      } else {
        section.classList.add('pt-4')
      }
    })
  }

  cancel() {
    this.removeActiveClassOnTriggerButton()
    this.element.setAttribute('data-dropdown-open-value', false)

    this.nextTick(() => {
      this.dispatch('canceled')
    })

    this.reset()
  }

  onPopoverHidden({ srcElement }) {
    if(srcElement === this.addButtonPopoverTarget) return
    this.cancel()
  }

  reset() {
    this.sectionTargets.forEach(section => section.remove())
    this.insertNewList('quick_reply')

    this.isEditing = false

    if(this.limitReached) {
      this.hide(this.contentTarget)
      this.show(this.limitTooltipTarget)
    }
  }

  closeByEscape() {
    if(this.isInvisible(this.menuTarget)) return
    this.cancelButtonTarget.click()
  }

  focus({ currentTarget }) {
    this.addClass(this.triggerButtonTarget, 'icon-button--compose--tiger')

    this.nextTick(() => {
      this.dispatch('focus', {
        target: this.buttonInputTargets[0],
      })
    })
  }

  removeGroup({ srcElement }) {
    const { type } = srcElement.dataset

    if(type === 'url' || type === 'phone') {
      this.addButtonTargets.find(button => button.dataset.type === type).classList.remove('hidden')
      this.sectionTargets.find(section => section.dataset.type === type).remove()
    }
  }

  removeActiveClassOnTriggerButton() {
    this.removeClass(this.triggerButtonTarget, 'icon-button--compose--tiger')
  }

  enable() {
    super.enable(this.triggerButtonTarget)

    this.dispatch('enable', {
      target: this.element.closest('[data-controller*="compose--template"]')
    })
  }

  disable() {
    super.disable(this.triggerButtonTarget)

    this.dispatch('disable', {
      target: this.element.closest('[data-controller*="compose--template"]')
    })
  }

  get activeItem() {
    return this.listTargets.find(list => this.isVisible(list))
  }

  get menuTarget() {
    return this.element.querySelector('[popover]')
  }

  get limitReached() {
    return this.buttonInputTargets.length >= 10 || Object.values(this.buttonsCountValue).reduce((acc, count) => acc + count, 0) >= 10
  }
}
