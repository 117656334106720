import ApplicationController from '../../application_controller'

export default class extends ApplicationController {
  static values = {
    type: String,
  }

  static targets = [
    'valueInput',
    'titleInput',
    'quickReply',
    'url',
    'phone',
    'removeButton',
  ]

  connect() {
    super.connect()
    this.validate()
  }

  focusRow() {
    if(this.hasTitleInputTarget) {
      this.focus(this.titleInputTarget, { moveCursorToEnd: true })
    } else {
      this.focusValueInput()
    }
  }

  focusValueInput() {
    this.focus(this.valueInputTarget, { moveCursorToEnd: true })
  }

  resetRow() {
    this.valueInputTarget.value = ''

    if(this.hasTitleInputTarget) {
      this.titleInputTarget.value = ''
    }
  }

  validate() {
    let validation;

    if(this.hasTitleInputTarget) {
      validation = this.titleInputTarget.value.trim().length > 0 && this.valueInputTarget.value.trim().length > 0
    } else {
      validation = this.valueInputTarget.value.trim().length > 0
    }

    if(validation) {
      this.element.setAttribute('data-valid', '')
    } else {
      this.element.removeAttribute('data-valid')
    }
  }

  remove() {
    if(this.element.dataset.type === 'phone') {
      this.element.parentElement.remove()
    } else {
      this.element.remove()
    }
  }

  showRemoveButton() {
    this.show(this.removeButtonTarget)
  }

  hideRemoveButton() {
    this.hide(this.removeButtonTarget)
  }

  toObject() {
    return {
      type: this.typeValue,
      title: this.hasTitleInputTarget ? this.titleInputTarget.value.trim() : this.valueInputTarget.value.trim(),
      value: this.valueInputTarget.value,
      index: Array.from(this.element.parentElement.children).indexOf(this.element),
    }
  }

  get isValid() {
    return false
  }

  get isInvalid() {
    return !this.isValid
  }
}
