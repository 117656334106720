import LineController from "../journeys/line_controller"
import { get } from "@rails/request.js"
import { BlankEndpoint, StraightConnector } from "@jsplumb/browser-ui"

export default class extends LineController {
  static targets = ["message", "container", "hiddenInput", "couponInput"]

  initialize() {
    this.kind = "message"
    super.initialize()
  }

  connect() {
    this._drawLine(false)

    if (!this.persistedValue) {
      setTimeout(() => this.togglePane({}), 0)
    }

    super.connect()
  }

  disconnect() {
    this.instance.deleteConnection(this.line)
    super.disconnect()
  }

  onWindowSizeChange() {
    this.repositionLine()
  }

  async togglePane({ target }) {
    if (!document.getElementById(`${this.element.id}_pane`)) {
      await get(target.dataset.url, {
        responseKind: "turbo-stream",
        query: {
          lineOrigin: this.lineValue,
          kind: this.kind,
          open_pane: true,
        },
      })

      this.triggerTarget.classList.toggle("halo--active")
    } else {
      super.togglePane()
    }
  }

  sync({ detail }) {
    this.repositionLine()
    if (detail.stepId !== this.element.id) return

    if (detail.commit === true) {
      this.triggerTarget.classList.remove("halo--active")
      this.triggerTarget.disabled = false
      this.persistedValue = true
    }

    // wait until this element has been updated
    // then Alert other steps to repaint their lines
    setTimeout(() => {
      this.notifyOtherStepsToRepositionLines()
    }, 200)
  }

  redraw({ detail }) {
    if(this.element.closest('[data-step="question"]')) return
    if (detail.target !== this.element.id) return

    this.instance.deleteConnection(this.line)
    this.childValue = detail.replaceWith
    this.line = this.draw(
      this.containerTarget,
      document.getElementById(detail.replaceWith),
      {
        fromOptions: { endpoint: BlankEndpoint.type, anchor: "Bottom" },
        toOptions: { endpoint: BlankEndpoint.type, anchor: "Top" },
        lineOptions: StraightConnector.type,
      }
    )
  }

  repositionLine() {
    if(this.element.closest('[data-step="question"]')) return

    this.instance.repaintEverything()
  }

  redrawEndLineIfNeeded({ detail }) {
    if(this.element.closest('[data-step="question"]')) return

    if (
      detail.target === this.element.id ||
      detail.parent === this.element.id
    ) {
      this.childValue = detail.child

      if (detail.child) {
        this.instance.deleteConnection(this.line)
        this.line = this.draw(
          this.containerTarget,
          document.getElementById(detail.child),
          {
            fromOptions: this.blankEndpoint("Bottom"),
            toOptions: this.blankEndpoint("Top"),
          }
        )
      } else {
        this._drawLine(true)
      }
    }

    if (detail.child === this.element.id) {
      this.lineValue = detail.parent
      this.instance.deleteConnection(this.line)
      this.branchValue = detail.branch
      this._drawLine(true)
    }

    setTimeout(() => this.repositionLine(), 0)
  }

  abortChanges({ detail }) {
    if (detail !== this.element.id) return

    if (this.currentValue) {
      setTimeout(() => {
        this.containerTarget.innerText = undefined
        this.containerTarget.innerText = this.currentValue
      }, 0)
    }

    this.triggerTarget.classList.remove("halo--active")

    if (!this.persistedValue) {
      this.dropzone.remove()
      this.element.parentElement.remove()
      this.element.remove()
    }
  }

  _drawLine(redraw = false) {
    if (redraw && this.line) {
      this.instance.deleteConnection(this.line)
    }

    if (this.hasChildStep) {
      this._drawLineForBranchless()
    }

    if(this.withinQuestion) {
      if(this.withinCondition) {
        return this._drawLineWithinCondition(redraw)
      }

      return this._drawLineWithinQuestion(redraw)
    }

    if(this.withinCondition) {
      this._drawLineWithinCondition(redraw)
      return
    }

    this._drawLineForBranchless()

    setTimeout(() => this.repositionLine(), 0)
  }

  _drawLineWithinCondition(redraw = false) {
    if(this.inBranch) {
      this._drawLineForBranch()
    } else {
      this._drawLineForElseBranch()
    }
  }

  _drawLineWithinQuestion(redraw = false) {
    if(this.withinAnswer) {
      this._drawLineForAnswer(redraw)
    } else {
      this._drawLineForGracePeriod(redraw)
    }
  }

  _drawLineForAnswer(redraw = false) {
    const answerElement = this.element.closest('[data-kind="answer"]')
    const questionElement = answerElement.closest('[data-kind="question"]')

    const questionController = this.application.getControllerForElementAndIdentifier(questionElement, 'automation--question')
    const endConnector = questionController.endConnectorTarget

    const lineOptions = {
      ...this.flowchartConnectorLineOptions,
      options: {
        ...this.flowchartConnectorLineOptions.options,
        cornerRadius: answerElement.previousElementSibling ? 0 : 10
      }
    }

    this.line = this.draw(
      this.containerTarget,
      endConnector,
      {
        lineOptions,
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(),
      }
    )
  }

  _drawLineForGracePeriod(redraw = false) {
    const gracePeriodElement = this.element.closest('[data-kind="grace"]')
    const questionElement = gracePeriodElement.closest('[data-kind="question"]')

    const questionController = this.application.getControllerForElementAndIdentifier(questionElement, 'automation--question')
    const endConnector = questionController.endConnectorTarget

    this.line = this.draw(
      this.containerTarget,
      endConnector,
      {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(),
      }
    )
  }

  remove({ detail }) {
    if (detail !== this.element.id) return

    this.dropzone.remove()
    this.element.remove()

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false
      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }
  }

  _drawLineForBranch() {
    const closestBranchElement = this.element.closest('[data-step--condition-target="branch"]')
    const conditionElement = closestBranchElement.closest('[data-kind="condition"]')

    const controller = this.application.getControllerForElementAndIdentifier(conditionElement, 'step--condition')

    const options = {
      ...this.flowchartConnectorLineOptions,
      options: {
        ...this.flowchartConnectorLineOptions.options,
        cornerRadius: closestBranchElement.previousElementSibling ? 0 : 10
      }
    }

    this.line = this.draw(
      this.containerTarget,
      controller.endTarget,
      {
        lineOptions: options,
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(""),
      }
    )
  }

  _drawLineForElseBranch() {
    this.line = this.draw(
      this.containerTarget,
      document.getElementById(this.childValue || this.endIdValue),
      {
        ...this.flowchartConnectorLineOptions,
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint("Right"),
      }
    )
  }

  _drawLineForSuccessBranch() {
    this.line = this.draw(
      this.containerTarget,
      document.getElementById(this.childValue || this.endIdValue),
      {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint("Left"),
      }
    )
  }

  _drawLineForBranchless() {
    this.line = this.draw(
      this.containerTarget,
      document.getElementById(this.childValue) || document.getElementById("end-connector"),
      {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(this.inBranch ? "" :"Top"),
        lineOptions: this.inBranch
          ? this.flowChartConnectorForBranch
          : StraightConnector.type,
      }
    )
  }

  childValueChanged() {
    this.dropzone.setAttribute("data-child", this.childValue)
  }

  branchValueChanged() {
    this.branchInputTarget.value = this.branchValue
    this.dropzone.setAttribute(
      "data-journeys--dropzone-branch-value",
      this.branchValue
    )
  }

  lineValueChanged() {
    this.parentTarget.value = this.lineValue
  }

  get dropzone() {
    return document.getElementById(`${this.element.id}_dropzone`)
  }

  inElseBranch(element = document.getElementById(this.parentTarget.value)) {
    return this.element.closest('[data-step--condition-target="elseContainer"]')
  }

  get inBranch() {
    return super.inBranch || this.element.closest('[data-step--condition-target="branch"]')
  }

  inSuccessBranch(element = document.getElementById(this.parentTarget.value)) {
    if (!element) {
      return false
    }

    if (element.dataset.branch === "success") {
      return true
    } else if (
      element.id === "journeys--editor" ||
      element.dataset.branch === "else"
    ) {
      return false
    } else {
      return this.inSuccessBranch(
        document.getElementById(element.dataset["step-MessageLineValue"])
      )
    }
  }

  get flowChartConnectorForBranch() {
    const originalOptions = this.flowchartConnectorLineOptions

    return {
      ...originalOptions,
      options: {
        ...originalOptions.options,
        cornerRadius: this.insideEdgeBranch
          ? originalOptions.options.cornerRadius
          : 0,
      },
    }
  }

  get withinCondition() {
    return this.element.closest('[data-kind="condition"]')
  }

  get withinQuestion() {
    return this.closestQuestion
  }

  get withinAnswer() {
    return this.element.closest('[data-kind="answer"]') && this.element.closest('[data-kind="answer"]').closest('[data-kind="question"]') === this.closestQuestion
  }

  get closestQuestion() {
    return this.element.closest('[data-kind="question"]')
  }
}
