import ApplicationController from '@/controllers/application_controller'

export default class extends ApplicationController {
  static targets = [
    'trigger',
    'option',
    'input',
  ]

  assign({ currentTarget }) {
    if(currentTarget.querySelector('[data-placeholder]')) {
      const placeholder = currentTarget.querySelector('[data-placeholder]').cloneNode(true)
      placeholder.classList.remove('hidden')

      this.triggerTarget.innerHTML = placeholder.outerHTML
    } else {
      this.triggerTarget.innerHTML = currentTarget.dataset.placeholder
    }

    this.optionTargets.forEach(option => {
      if(option === currentTarget) {
        option.classList.add('bg-lavender-light')
      } else {
        option.classList.remove('bg-lavender-light')
      }
    })

    this.inputTarget.value = currentTarget.dataset.optionValue
  }
}
