import PaneController from "../../journeys/pane_controller"
import { get } from "@rails/request.js"

export default class extends PaneController {
  static values = {
    stepId: String,
    wait: Number,
    waitDuration: { type: String, default: 'minutes' },
  }

  static targets = ["error", "option", "select", "saveChanges", "condition", "wait", 'waitSummary', 'waitPeriodSelect', 'waitPeriodInput', 'waitDetails']

  connect() {
    this.optionValue = this.selectTarget.value

    this.waitDetailsTarget.addEventListener('toggle', ({ target }) => {
      if(target.open) {
        this.focus(this.waitPeriodInputTarget, { moveCursorToEnd: true })
      }
    })

    super.connect()
  }

  sync(commit = false) {
    this.dispatch("sync", {
      detail: {
        stepId: this.stepIdValue,
        commit,
      },
      target: document.documentElement,
    })

    this.hasUnsavedChanges = false
  }

  saveAndHide() {
    this.sync(true)
    this._hide()
    this.optionValue = this.selectTarget.value

    this.clearQueue()
    if (this.hasErrorTarget) {
      this.errorTarget.remove()
    }

    this.conditionTargets.forEach((condition) => {
      if(condition.classList.contains("hidden")) {
        this.dispatch("condition:remove", {
          target: condition
        })
      }
    })

    this.waitDetailsTarget.open = false
  }

  cancel() {
    this._abort()
    this._hide()
    if (this.selectTarget.value !== this.optionValue) {
      this.element
        .querySelector(`[data-option-value='${this.optionValue}']`)
        .click()
    }

    setTimeout(this.clearQueue, 1000)
  }

  _abort() {
    this.dispatch("sync:abort", {
      detail: this.stepIdValue,
      target: document.documentElement,
    })
    this.hasUnsavedChanges = false
  }


  disableWait() {
    this.waitTarget.classList.add('text-night-40')
    this.waitTarget.querySelectorAll('input:not([type="radio"]), select').forEach(this.disable)

    this.dispatch('disable', {
      target: this.waitPeriodSelectTarget,
    })

    this.waitSummaryTarget.innerText = t.automation.condition.wait.summary.disabled
  }

  enableWait() {
    this.waitTarget.classList.remove('text-night-40')
    this.waitTarget.querySelectorAll('input:not([type="radio"]), select').forEach(this.enable)

    this.dispatch('enable', {
      target: this.waitPeriodSelectTarget,
    })

    this.syncWaitPeriodLabel()
    this.focus(this.waitPeriodInputTarget, { moveCursorToEnd: true })
  }

  onWaitPeriodChange({ target }) {
    if(!target.value) return

    this.waitValue = parseInt(target.value)
    this.syncWaitPeriodLabel()
  }

  onWaitDurationChange({ detail: value }) {
    this.waitDurationValue = value

    this.syncWaitPeriodLabel()
    this.focus(this.waitPeriodInputTarget, { moveCursorToEnd: true })
  }

  syncWaitPeriodLabel() {
    if(this.waitValue === 1) {
      this.waitSummaryTarget.innerText = t.automation.condition.wait.summary[this.waitDurationValue].one
    } else {
      this.waitSummaryTarget.innerText = t.automation.condition.wait.summary[this.waitDurationValue].other.replace('%{count}', this.waitValue)
    }
  }

  onConditionClick({ currentTarget }) {
    this.setHasUnsavedChanges()
    get(currentTarget.dataset.url, { responseKind: "turbo-stream" })
  }

  setHasUnsavedChanges() {
    this.hasUnsavedChanges = true
    window.paneId = this.element.id
  }

  enableSubmit() {
    this.saveChangesTarget.disabled = false
  }

  disableSubmit() {
    this.saveChangesTarget.disabled = true
  }
}
