import LineController from "../journeys/line_controller"

import { commaSeperatedListWithOrConjunction } from "@/controllers/models/sentence";

export default class extends LineController {
  static values = {
    retriesCount: {type: Number, default: 2},
    gracePeriod: {type: Number, default: 6},
    gracePeriodInterval: {type: String, default: 'hours'},
    answers: Array,
  }

  static targets = [
    "trigger",
    "dropzone",
    "branchInput",
    "removedInput",
    "answersInput",
    "body",
    "answersContainer",
    "answerElement",
    "answerElementTemplate",
    "rootConnector",
    "endConnector",
    "answerPreviewTemplate",
    "removedInput",
    "child",
    "gracePeriodElement",
  ]

  initialize() {
    super.initialize()

    this.state = {
      retriesCount: this.retriesCountValue,
      retriesEnabled: this.retriesCountValue > 0,
      gracePeriod: this.gracePeriodValue,
      gracePeriodInterval: this.gracePeriodIntervalValue,
    }

    this.retriesEnabled = this.state.retriesEnabled
  }

  get retriesDetailTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="retriesDetail"]')
  }

  get retriesInputTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="retriesInput"]')
  }

  get retriesSummaryTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="retriesSummary"]')
  }

  get retriesSuffixTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="retriesSuffix"]')
  }

  get gracePeriodDetailsTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="gracePeriodDetails"]')
  }

  get gracePeriodInputTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="gracePeriodInput"]')
  }

  get gracePeriodSummaryTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="gracePeriodSummary"]')
  }

  get answersDetailsTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="answersDetails"]')
  }

  get answersDetailsSummaryTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="answersDetailsSummary"]')
  }

  get submitTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="submit"]')
  }

  get smsPreviewTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="smsPreview"]')
  }

  get whatsappPreviewTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="whatsappPreview"]')
  }

  get hasWhatsappPreviewTarget() {
    return !!this.whatsappPreviewTarget
  }

  get composeTarget() {
    return this.paneTarget.querySelector('[data-automation--question-target="compose"]')
  }

  connect() {
    this._drawLine(false)

    if (!this.persistedValue) {
      setTimeout(() => this.togglePane({}), 0)
    }

    super.connect();

    this.retriesDetailTarget.addEventListener('toggle', ({target}) => {
      if (target.open && !this.retriesInputTarget.disabled) {
        this.focus(this.retriesInputTarget, {moveCursorToEnd: true})
      }
    })

    this.gracePeriodDetailsTarget.addEventListener('toggle', ({target}) => {
      if (target.open) {
        this.focus(this.gracePeriodInputTarget, {moveCursorToEnd: true})
      }
    })

    this.answersDetailsTarget.addEventListener('toggle', ({target}) => {
      if(target.open) return

      const answers = this.state.answers.map(answer => answer.body).filter(answer => answer)

      if(answers.length === 0) {
        this.answersDetailsSummaryTarget.textContent = t.automation.answer.summary.zero
      } else if(answers.length === 1) {
        this.answersDetailsSummaryTarget.textContent = t.automation.answer.summary.one.replace("%{value}", answers[0])
      } else {
        this.answersDetailsSummaryTarget.textContent = t.automation.answer.summary.other.replace(
          "%{value}",
          commaSeperatedListWithOrConjunction(answers)
        )
      }
    })

    this.paneSnapshot = this.paneTarget.cloneNode(true)
  }

  disconnect() {
    this.instance.deleteConnection(this.rootToGracePeriodConnection)
    this.instance.deleteConnection(this.gracePeriodToEndConnection)

    this.instance.deleteConnection(this.rootConnector)

    super.disconnect()
  }

  showPaneAndFocusWait() {
    // if(this.paneIsVisible) return

    this.togglePane({ focus: 'wait' })
  }

  onComposeInitialization({detail: composeSnapshot}) {
    this.state = {
      ...this.state,
      composeSnapshot,
    }

    this.submitTarget.disabled = !this.state.composeSnapshot?.string || this.state.answers.map(answer => answer.body).filter((answer) => !answer).length > 0

    this.composeSnapshot = composeSnapshot

    this.updatePreview()
  }

  onComposeChange({detail: composeSnapshot}) {
    this.state = {
      ...this.state,
      composeSnapshot,
    }


    this.submitTarget.disabled = !this.state.composeSnapshot?.string || this.state.answers.map(answer => answer.body).filter((answer) => !answer).length > 0
    this.updatePreview()
  }

  onAnswersValidation({detail: state}) {
    this.state = {
      ...this.state,
      ...state,
    }

    this.submitTarget.disabled = !this.state.composeSnapshot?.string || this.state.answers.map(answer => answer.body).filter((answer) => !answer).length > 0

    this.updatePreview()
  }

  togglePane({ focus = 'compose'} = {}) {
    if (window.paneId) {
      return window.dispatchEvent(
        new CustomEvent("pane:alert", {detail: this.element.id})
      )
    }

    this.paneTarget.classList.toggle("-right-full")
    this.paneTarget.classList.toggle("right-0")

    this.triggerTarget.classList.toggle("halo--active")

    if(this.paneIsVisible) {
      this.nextTick(() => {
        if(focus === 'wait') {
          this.gracePeriodDetailsTarget.open = true
          this.focus(this.gracePeriodInputTarget, { moveCursorToEnd: true })

          this.gracePeriodDetailsTarget.scrollIntoView({ behaviour: 'instant' })
        } else {
          this.dispatch('focus', {
            target: this.composeTarget,
          })
        }
      }, 300)
    }
  }

  repositionLine() {
    this.instance.repaintEverything()

    this.answerElementTargets.forEach((answerElement) => {
      this.dispatch('repaint', {
        target: answerElement,
      })
    })
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  abortChanges() {
    if(!this.persistedValue) {
      return this.remove()
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.delayed(() => {
      this.paneTarget.replaceWith(this.paneSnapshot.cloneNode(true))
    }, 300)

    this.retriesCountValue = this.state.retriesCount
    this.retriesEnabled = this.state.retriesEnabled
    this.retriesInputTarget.value = this.retriesCountValue

    this.gracePeriodValue = this.state.gracePeriod
    this.gracePeriodIntervalValue = this.state.gracePeriodInterval

    this.answersInputTarget.value = this.state.answers.map(answer => answer.body).filter(answer => answer).join(',')
    this.composeSnapshot = this.state.composeSnapshot
  }

  save() {
    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.paneSnapshot = this.paneTarget.cloneNode(true)

    if(!this.persistedValue) {
      this.answersContainerTarget.innerHTML = ''
    }

    this.state.answers.forEach((answer, index) => {
      let element = this.answerElementTargets.find(element => element.dataset.id === answer.id)

      if(element) {
        return this.dispatch('update', {
          target: element,
          detail: answer,
        })
      }

      const id = `${crypto.randomUUID()}-${index}`
      const domId = `step_${id}`

      element = this.answerElementTemplateTarget.cloneNode(true)

      element.classList.remove('hidden')
      element.id = domId

      if(answer.body.toLowerCase() === t.automation.answers.answer.any.toLowerCase()) {
        element.querySelector('[data-description]').textContent = answer.body
      } else {
        element.querySelector('[data-description]').textContent = `${answer.text} ${answer.body}`
      }

      element.setAttribute('data-automation--question-target', 'answerElement')
      element.setAttribute('data-id', id)

      const dropzone = element.querySelector('[data-controller="journeys--dropzone"]')

      dropzone.id = `${domId}-dropzone`
      dropzone.setAttribute('data-journeys--dropzone-line-value', domId)

      element.querySelector('[data-body-input]').value = answer.body
      element.querySelector('[data-query-input]').value = answer.query

      element.querySelectorAll('input').forEach((input) => {
        input.name = input.name.replace(':id', id)
        this.enable(input)
      })

      this.answersContainerTarget.appendChild(element)

      this.notifyOtherStepsToRepositionLines()

      this.state.answers[index] = {
        ...this.state.answers[index],
        ...answer,
        id,
      }
    })

    this.dispatch('update', {
      target: this.paneTarget,
      detail: this.state.answers,
    })

    this.drawFromRootToAnswers()
    this.drawToGracePeriodElement()

    this.nextTick(() => this.instance.repaintEverything())

    this.bodyTarget.innerText = this.state.composeSnapshot.string

    this.gracePeriodElementTarget.querySelector('[data-interval-input]').value = this.gracePeriodIntervalValue
    this.gracePeriodElementTarget.querySelector('[data-value-input]').value = this.gracePeriodValue

    this.persistedValue = true

    this.answersInputTarget.value = this.state.answers.map(answer => answer.body).filter(answer => answer).join(',')
    this.state.retriesCount = this.retriesCountValue

    this.composeSnapshot = this.state.composeSnapshot

    this.closeDetailElements()
  }

  closeDetailElements() {
    [this.retriesDetailTarget, this.answersDetailsTarget, this.gracePeriodDetailsTarget]
      .filter(detail => detail.open)
      .forEach(detail => detail.open = false)
  }

  remove() {
    this.instance.deleteConnection(this.rootToGracePeriodConnection)
    this.instance.deleteConnection(this.gracePeriodToEndConnection)

    this.instance.deleteConnection(this.rootConnector)
    this.instance.deleteConnection(this.endToNextConnection)

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false

      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }

    this.paneTarget.remove()
    this.dropzone.remove()
    this.element.remove()
  }

  _drawLine(redraw = false) {
    this.rootConnector = this.draw(this.bodyTarget, this.rootConnectorTarget, {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Top"),
      lineOptions: {
        ...this.flowchartConnectorLineOptions,
        options: {
          cornerRadius: 0,
          stub: [1, 0],
          midpoint: 1,
          alwaysRespectStubs: true,
        },
      }
    })

    this.drawFromRootToAnswers()
    this.drawToGracePeriodElement()
    this.drawFromEndConnectorToNextElement()
  }

  drawFromRootToAnswers() {
    this.delayed(() => {
      this.answerElementTargets.map((answerElement, index) => {
        this.dispatch('draw', {
          target: answerElement,
          detail: {
            root: this.rootConnectorTarget,
            end: this.endConnectorTarget,
            index,
          }
        })
      })
    }, 0)
  }

  drawToGracePeriodElement() {
    if(this.rootToGracePeriodConnection) {
      this.instance.deleteConnection(this.rootToGracePeriodConnection)
      this.instance.deleteConnection(this.gracePeriodToEndConnection)
    }

    const paragraph = this.gracePeriodElementTarget.querySelector('[data-description]')
    const header = this.gracePeriodElementTarget.querySelector('[data-header]')

    if (this.gracePeriodValue === 1) {
      paragraph.textContent = t.automation.question.grace_period.element[this.gracePeriodIntervalValue].one
    } else {
      paragraph.textContent = t.automation.question.grace_period.element[this.gracePeriodIntervalValue].other.replace('%{count}', this.gracePeriodValue)
    }

    this.nextTick(() => {
      this.dispatch('draw', {
        target: this.gracePeriodElementTarget,
        detail: {
          root: this.rootConnectorTarget,
          end: this.endConnectorTarget,
          index: 0,
        }
      })
    })
  }

  updateEndConnectionReferenceOnConnect(event) {
    this.repositionLine()

    const { detail } = event

    if(detail.target !== this.element.id) return;

    if(this.endToNextConnection) {
      this.instance.deleteConnection(this.endToNextConnection)
    }

    const element = document.getElementById(detail.replaceWith)

    this.endToNextConnection = this.draw(
      this.endConnectorTarget,
      element.querySelector('[data-connector]') || element,
      {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint("Top"),
    })
  }

  updateEndConnectionReferenceOndisconnect(event) {
    this.repositionLine()

    const { detail } = event

    if(detail.target !== this.element.id) return;

    if(detail.child) {
      if(this.endToNextConnection) {
        this.instance.deleteConnection(this.endToNextConnection)
      }

      const element = document.getElementById(detail.child)

      this.endToNextConnection = this.draw(
        this.endConnectorTarget,
        element.querySelector('[data-connector]') || element,
        {
          fromOptions: this.blankEndpoint("Bottom"),
          toOptions: this.blankEndpoint("Top"),
      })
    } else {
      this.instance.deleteConnection(this.endToNextConnection)
      this.nextTick(() => this.drawFromEndConnectorToNextElement())
    }

    this.nextTick(() => this.instance.repaintEverything())

  }

  drawFromEndConnectorToNextElement() {
    if(this.endToNextConnection) {
      this.instance.deleteConnection(this.endToNextConnection)
    }

    if(this.element.parentElement.closest('[data-kind="question"]')) {
      const question = this.element.parentElement.closest('[data-kind="question"]')
      const questionController = this.application.getControllerForElementAndIdentifier(question, 'automation--question')
      const endConnector = questionController.endConnectorTarget

      this.endToNextConnection = this.draw(this.endConnectorTarget, endConnector, {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(),
        lineOptions: {
          ...this.flowchartConnectorLineOptions,
          options: {
            cornerRadius: (this.element.closest('[data-kind="answer"]') && !this.element.closest('[data-kind="answer"]').previousElementSibling) || this.element.closest('[data-kind="grace"]') ? 10 : 0,
            stub: [1, 0],
            midpoint: 1,
            alwaysRespectStubs: true,
          }
        }
      })

      return
    }

    let element = this.element.nextElementSibling?.querySelector('[data-connector]') || document.getElementById("end-connector")

    if(this.element.closest('[data-step="condition"]')) {
      element = this.element.closest('[data-step="condition"]').querySelector('[data-step--condition-target="end"]')
    }

    this.endToNextConnection = this.draw(
      this.endConnectorTarget,
      element,
      {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint(),
    })
  }

  redraw({ detail }) {
    if (detail.target !== this.element.id) return
    this.instance.repaintEverything()
  }

  enableRetries() {
    this.retriesEnabled = true

    this.retriesContainerTarget.classList.remove('text-night-40')

    this.enable(this.retriesInputTarget)
    this.focus(this.retriesInputTarget, {moveCursorToEnd: true})

    this.retriesCountValueChanged()
  }

  disableRetries() {
    this.retriesEnabled = false

    this.retriesContainerTarget.classList.add('text-night-40')
    this.disable(this.retriesInputTarget)

    this.retriesSummaryTarget.textContent = t.automation.question.retries.summary.disabled
  }

  onRetriesChange() {
    if (!this.retriesInputTarget.value) return
    this.retriesCountValue = parseInt(this.retriesInputTarget.value)
  }

  onGracePeriodChange() {
    if (!this.gracePeriodInputTarget.value) return
    this.gracePeriodValue = parseInt(this.gracePeriodInputTarget.value)
  }

  onGracePeriodIntervalChange({detail: interval}) {
    this.gracePeriodIntervalValue = interval
  }

  retriesCountValueChanged() {
    if(this.retriesCountValue === 0) {
      this.retriesSummaryTarget.textContent = t.automation.question.retries.summary.disabled
    } else if (this.retriesCountValue === 1) {
      this.retriesSummaryTarget.textContent = t.automation.question.retries.summary.one
      this.retriesSuffixTarget.textContent = t.automation.question.retries.radio.times.one
    } else {
      this.retriesSummaryTarget.textContent = t.automation.question.retries.summary.other.replace('%{count}', this.retriesCountValue)
      this.retriesSuffixTarget.textContent = t.automation.question.retries.radio.times.other.replace('%{count}', this.retriesCountValue)
    }
  }

  gracePeriodValueChanged() {
    if (this.gracePeriodValue === 1) {
      this.gracePeriodSummaryTarget.textContent = t.automation.question.grace_period.summary[this.gracePeriodIntervalValue].one
    } else {
      this.gracePeriodSummaryTarget.textContent = t.automation.question.grace_period.summary[this.gracePeriodIntervalValue].other.replace('%{count}', this.gracePeriodValue)
    }
  }

  updatePreview() {
    const answers = this.state.answers.map(answer => answer.body).filter(answer => answer && answer.toLowerCase() !== t.automation.answers.answer.any.toLowerCase())

    if(!this.hasWhatsappPreviewTarget) {
      if(!this.state.composeSnapshot?.string) {
        this.smsPreviewTarget.querySelector('p').innerText = t.automation.question.preview.empty
      } else {
        this.smsPreviewTarget.querySelector('p').textContent = t.automation.question.preview.sms
          .replace('%{question}', this.state.composeSnapshot.string)
          .replace('%{options}', commaSeperatedListWithOrConjunction(this.state.answers.map(answer => answer.body)))
      }

      return
    }

    if(!this.state.composeSnapshot?.string) {
      this.whatsappPreviewTarget.querySelector('p').innerText = t.automation.question.preview.empty

      if(!answers || answers.length === 0 || answers.every(answer => !answer)) {
        return this.whatsappPreviewTarget.querySelector('ul').classList.add('hidden')
      }

      this.smsPreviewTarget.querySelector('p').innerText = t.automation.question.preview.empty
      return
    }

    this.whatsappPreviewTarget.querySelector('p').innerText = this.state.composeSnapshot.string
    this.smsPreviewTarget.querySelector('p').innerText = this.state.composeSnapshot.string

    this.whatsappPreviewTarget.querySelector('ul').innerHTML = ''

    if(!answers || answers.length === 0 || answers.every(answer => !answer)) {
      return this.whatsappPreviewTarget.querySelector('ul').classList.add('hidden')
    }

    answers.forEach((answer) => {
      const element = this.answerPreviewTemplateTarget.cloneNode(true)

      element.classList.remove('hidden')
      element.removeAttribute('data-automation--question-target')

      element.querySelector('p').innerText = answer

      this.whatsappPreviewTarget.querySelector('ul').appendChild(element)
    })


    this.smsPreviewTarget.querySelector('p').textContent = t.automation.question.preview.sms
      .replace('%{question}', this.state.composeSnapshot.string)
      .replace('%{options}', commaSeperatedListWithOrConjunction(answers))

    this.whatsappPreviewTarget.querySelector('ul').classList.remove('hidden')
  }

  gracePeriodIntervalValueChanged() {
    this.gracePeriodValueChanged()
  }

  get paneIsInvisible() {
    return this.paneTarget.classList.contains("-right-full")
  }

  get paneIsVisible() {
    return this.paneTarget.classList.contains("right-0")
  }

  get hasUnsavedChanges() {
    return this.answersChanged || this.retriesChanged || this.gracePeriodChanged || this.questionChanged
  }

  get answersChanged() {
    return this.state.answers.map(answer => answer.body).join(',') !== this.answersInputTarget.value
  }

  get retriesChanged() {
    return this.state.retriesCount !== this.retriesCountValue || this.state.retriesEnabled !== this.retriesEnabled
  }

  get gracePeriodChanged() {
    return this.state.gracePeriod !== parseInt(this.gracePeriodValue) || this.state.gracePeriodInterval !== this.gracePeriodIntervalValue
  }

  get questionChanged() {
    return this.composeSnapshot.string !== this.state.composeSnapshot?.string
  }

  get dropzone() {
    return document.getElementById(`${this.element.id}_dropzone`)
  }

  get paneTarget() {
    return document.getElementById(`${this.element.id}_pane`)
  }
}
