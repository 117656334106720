import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    inputName: String,
    previewable: { type: Boolean, default: false },
    buttonGroups: Array
  }

  static targets = [
    'buttonsContainer',
    'buttonGroupTemplate',
    'buttonTemplate',
    'group',
    'compose',
    'buttonManager'
  ]

  insertButtons({ detail: buttonGroups }) {
    buttonGroups.forEach(({ buttons, type}) => {
      if(this.buttonGroupsValue.find(group => group.type === type)) {
        this.buttonGroupsValue = this.buttonGroupsValue.map(group => {
          if(group.type === type) {
            return {
              type,
              buttons: [...group.buttons, ...buttons]
            }
          } else {
            return group
          }
        })

        this.insertToCurrentGroup({ buttons, type })
      } else {
        this.buttonGroupsValue = [...this.buttonGroupsValue, { buttons, type }]
        this.insertNewGroup({ buttons, type })
      }
    })

    if(this.isPreviewable) {
      this.previewButtons()
    }

    this.show(this.buttonsContainerTarget)
  }

  updateButtons({ detail: buttonGroups }) {
    this.buttonGroupsValue = buttonGroups
    this.groupTargets.forEach(group => group.remove())

    buttonGroups.forEach(({ buttons, type }) => {
      this.insertNewGroup({ buttons, type })
    })

    if(this.isPreviewable) {
      this.previewButtons()
    }
  }

  loadTemplateButtons({ detail }) {
    this.buttonsContainerTarget.innerHTML = ''
    this.buttonGroupsValue = detail.buttons

    this.buttonGroupsValue.forEach(({ buttons, type }) => {
      this.insertNewGroup({ buttons, type })
    })

    this.insertInputs()
    this.previewButtons()
  }

  restoreButtons({ detail: oldGroup }) {
    this.buttonGroupsValue = oldGroup

    this.groupTargets.forEach(group => group.remove())

    this.buttonGroupsValue.forEach(({ buttons, type }) => {
      this.insertNewGroup({ buttons, type })
    })

    this.insertInputs()
    this.previewButtons()

    this.show(this.buttonsContainerTarget)
  }

  insertNewGroup({ buttons, type, element }) {
    const buttonGroupElement = this.buttonGroupTemplateTarget.cloneNode(true)

    buttonGroupElement.setAttribute('data-compose--template-target', 'group')
    buttonGroupElement.setAttribute('data-type', type)

    const contentElement = buttonGroupElement.querySelector('[data-content]')

    this.insertButtonIntoGroup(buttons, type, contentElement, 0)

    this.show(buttonGroupElement)
    this.show(this.buttonsContainerTarget)

    this.insertInputs()
    this.buttonsContainerTarget.appendChild(buttonGroupElement)
  }

  insertToCurrentGroup({ buttons, type }) {
    const groupElement = this.groupTargets.find(group => group.dataset.type === type)
    const contentElement = groupElement.querySelector('[data-content]')

    let index = contentElement.children.length

    this.insertButtonIntoGroup(buttons, type, contentElement, index)
    this.insertInputs()
  }

  insertButtonIntoGroup(buttons, type, contentElement, index) {
    buttons.forEach((button) => {
      const buttonElement = this.buttonTemplateTargets.find(template => template.dataset.type === type).cloneNode(true)

      buttonElement.querySelector('[data-text]').innerText = button.title

      buttonElement.setAttribute('data-type', type)
      buttonElement.setAttribute('data-index', index)

      buttonElement.removeAttribute('data-compose--template-target')

      this.show(buttonElement)
      contentElement.appendChild(buttonElement)

      index++
    })
  }

  focusGroup({ currentTarget }) {
    this.dispatch('edit', {
      target: this.buttonManagerTarget,
      detail: {
        groups: this.buttonGroupsValue,
        type: currentTarget.dataset.type,
        index: currentTarget.dataset.index,
      }
    })
  }

  disable() {
    this.buttonsContainerTarget.classList.add('grayscale', 'cursor-not-allowed', 'pointer-events-none')
    this.buttonsContainerTarget.querySelectorAll('input').forEach(super.disable)
  }

  enable() {
    this.buttonsContainerTarget.classList.remove('grayscale', 'cursor-not-allowed', 'pointer-events-none')
    this.buttonsContainerTarget.querySelectorAll('input').forEach(super.enable)
  }

  clear() {
    this.buttonsContainerTarget.innerHTML = ''
    this.buttonGroupsValue = []

    this.hide(this.buttonsContainerTarget)
  }

  displayAttachmentPreview({ detail: attachmentElement }) {
    if(!this.isPreviewable) return

    this.attachmentElement = attachmentElement

    if(document.querySelector('[data-controller="template--preview"]')) {
      this.dispatch('attachment:preview', {
        target: document.querySelector('[data-controller="template--preview"]'),
        detail: attachmentElement
      })
    }
  }

  hideAttachmentPreview() {
    if(!this.isPreviewable) return

    if(document.querySelector('[data-controller="template--preview"]')) {
      this.dispatch('attachment:preview', {
        target: document.querySelector('[data-controller="template--preview"]'),
        detail: 0
      })
    }
  }

  displayBodyPreview({ detail }) {
    if(!this.isPreviewable) return

    this.dispatch('body:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail
    })
  }

  displayFooterPreview({ target }) {
    if(!this.isPreviewable) return

    this.dispatch('footer:preview', {
      target: document.querySelector('[data-controller="template--preview"]'),
      detail: target.value
    })
  }

  previewButtons() {
    if(this.hasComposeTarget) {
      this.dispatch('buttons:preview', {
        target: this.composeTarget,
        detail: this.buttonGroupsValue
      })
    } else {
      this.dispatch('buttons:preview', {
        target: document.querySelector('[data-controller="template--preview"]'),
        detail: this.buttonGroupsValue
      })
    }
  }

  insertInputs() {
    this.element.querySelector('[data-inputs-container]').innerHTML = ''

    let index = 1

    this.buttonGroupsValue.forEach(({ buttons, type }) => {
      buttons.forEach((button) => {
        const methodName = type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('')
        this[`insert${methodName}Inputs`](button, index++)
      })
    })
  }

  insertQuickReplyInputs(button, index) {
    ['title', 'value'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = attribute === 'title' ? button.text || button.title : button[attribute]

      this.insertInput(name, value)
    })

    const type = `${this.inputNameValue}[buttons][][type]`
    this.insertInput(type, 'quick_reply')
  }

  insertCopyCodeInputs(button, index) {
    ['text', 'type'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = button[attribute]

      this.insertInput(name, value)
    })
  }

  insertUrlInputs(button, index) {
    ['title', 'value'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = attribute === 'title' ? button.text || button.title : button[attribute]

      this.insertInput(name, value)
    })

    const type = `${this.inputNameValue}[buttons][][type]`
    this.insertInput(type, 'url')
  }

  insertPhoneInputs(button, index) {
    ['title', 'value'].forEach(attribute => {
      const name = `${this.inputNameValue}[buttons][][${attribute}]`
      const value = attribute === 'title' ? button.text || button.title : button[attribute]

      this.insertInput(name, value)
    })

    const type = `${this.inputNameValue}[buttons][][type]`
    this.insertInput(type, 'phone')
  }

  insertInput(name, value) {
    const input = document.createElement('input')

    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)

    input.value = value

    this.element.querySelector('[data-inputs-container]').appendChild(input)
  }

  get isPreviewable() {
    return this.previewableValue || document.querySelector('[data-controller="template--preview"]')
  }
}
