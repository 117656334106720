import { Controller } from "@hotwired/stimulus"
import interact from "interactjs"

export default class extends Controller {
  static targets = ["draggable"]
  static values = { url: String }

  static classes = ["active", "inactive"]

  initialize() {
    this.position = { x: 0, y: 0 }
    this.draggingEnabled = true
  }

  connect() {
    this.interactable = interact(this.draggableTarget).draggable({
      onstart: this.disablePanning.bind(this),
      onmove: this.onDrag.bind(this),
      onend: this.onDragEnd.bind(this),
    })
  }

  disablePanning() {
    this.dispatch('drag:start')
  }

  enableDragging() {
    this.draggingEnabled = true

    this.interactable.draggable(true)
    this.draggableTarget.classList.remove(...this.inactiveClasses)
    this.draggableTarget.classList.add(...this.activeClasses)

    this.parent.classList.remove("grayscale")
  }

  disableDragging() {
    this.draggingEnabled = false

    this.interactable.draggable(false)
    this.draggableTarget.classList.add(...this.inactiveClasses)
    this.draggableTarget.classList.remove(...this.activeClasses)

    this.parent.classList.add("grayscale")
  }

  // private

  onDrag(event) {
    if (this.draggingDisabled) return

    this.position.x += event.dx
    this.position.y += event.dy

    event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`
  }

  onDragEnd(event) {
    if (event.relatedTarget?.id === "dropzone") {
      this.position = { x: 0, y: 0 }
    } else {
      event.target.style.removeProperty("transform")
      this.position = { x: 0, y: 0 }
    }

    this.dispatch('drag:end')
  }

  get draggingDisabled() {
    return this.draggingEnabled === false
  }

  get parent() {
    return document.getElementById(this.element.dataset.parent)
  }
}
