import ApplicationController from '@/controllers/application_controller'

export default class extends ApplicationController {
  static values = {
    query: { type: String, default: 'similarity' },
    body: String,
  }

  static targets = [
    'input',
    'queryOption',
    'queryPlaceholder',
    'emojiNotice',
  ]

  connect() {
    if(!this.isTemplate) {
      this.enable(this.inputTarget)
    }

    super.connect()
  }

  onQueryChange({ currentTarget }) {
    this.queryValue = currentTarget.dataset.query
    this.queryPlaceholderTarget.querySelector('p').innerText = currentTarget.dataset.text

    this.queryOptionTargets.forEach((option) => {
      if(option === currentTarget) {
        option.classList.add('bg-lavender-light')
      } else {
        option.classList.remove('bg-lavender-light')
      }
    })

    this.focus(this.inputTarget, { moveCursorToEnd: true })
    this.changed()
  }

  changed() {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}]/gu;

    if (emojiRegex.test(this.inputTarget.value)) {
      this.inputTarget.value = this.inputTarget.value.replace(emojiRegex, '')
      this.emojiNoticeTarget.classList.remove('hidden')
    } else {
      this.emojiNoticeTarget.classList.add('hidden')
    }

    this.dispatch('changed', {
      detail: {
        id: this.element.dataset.id,
        query: this.queryValue,
        body: this.inputTarget.value,
        text: t.applied_filters.query[this.queryValue],
      }
    })
  }

  get isTemplate() {
    return this.element.getAttribute('data-automation--answers-target') === 'template'
  }
}
