import ApplicationController from '../application_controller'
import { useURLValidations } from "../mixins/useURLValidations"
import {Piece} from "@/controllers/compose/models/piece";

export default class extends ApplicationController {
  static values = {
    content: String,
    smsCount: Number,
    url: String,
  }

  static targets = ['paragraphs']
  connect() {
    useURLValidations(this)
    super.connect()
  }

  updatePreview({ detail }) {
    this.paragraphsTarget.innerHTML = ''

    if(!detail.string) {
      this.paragraphsTarget.innerHTML = this.translations.campaign.broadcasts.new.fields.body.placeholder
      return
    }

    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = detail.html

    tempDiv.querySelectorAll('figure').forEach(figure => {
      const { link, id } = JSON.parse(figure.dataset.trixAttachment)
      figure.replaceWith(this.constructLinkElement({ link, url: detail.links[id].url }))
    })

    this.paragraphsTarget.innerHTML = tempDiv.innerHTML
  }

  constructLinkElement({ link, url}) {
    const linkElement = document.createElement('a')
    this.addClass(linkElement, 'link-primary')

    linkElement.innerText = link
    linkElement.href = url
    linkElement.target = '_blank'

    return linkElement
  }
}
