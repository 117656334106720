import { Controller } from "@hotwired/stimulus"
import iro from "@jaames/iro"

export default class extends Controller {
  static targets = ["picker", "preview", "colorPickerControls", "colorInput"]

  static values = {
    default: { type: String, default: "#000000" },
    closeOnSubmit: { type: Boolean, default: true },
  }

  connect() {
    this.colorPicker = new iro.ColorPicker(this.pickerTarget, {
      width: 280,
      color: this.defaultValue,
      layout: [
        {
          component: iro.ui.Box,
          options: {
            boxHeight: 160,
          },
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: "hue",
          },
        },
      ],
    })

    this.colorPicker.color.set(this.defaultValue)
    this.changeColorTo(this.colorPicker.color.hexString.toUpperCase())

    this.colorPicker.on("input:change", (color) => {
      this.changeColorTo(color.hexString)

      this.dispatch("change", {
        detail: {
          rgba: color.rgbaString,
          hex: color.hexString,
        },
      })
    })

    this.colorPicker.on("color:change", (color) => {
      this.changeColorTo(color.hexString)

      this.dispatch("change", {
        detail: {
          rgba: color.rgbaString,
          hex: color.hexString,
        }
      })
    })
  }

  changeColor({ target }) {
    this.colorPicker.color.set(target.dataset.color)

    this.colorInputTarget.value = target.dataset.color
      .split("#")[1]
      .toUpperCase()
  }

  syncColor({ detail }) {
    this.colorPicker.color.set(detail)
  }

  setColor() {
    this.colorInputTarget.value = this.colorInputTarget.value.toUpperCase()
    try {
      this.colorPicker.color.set(`#${this.colorInputTarget.value}`)
    } catch (e) {}

    if (this.closeOnSubmitValue) {
      setTimeout(() => {
        this.element.classList.add("hidden")
      }, 0)
    }
  }

  // private

  changeColorTo(color) {
    this.colorInputTarget.value = color.split("#")[1]

    this.previewTargets.forEach((previewTarget) => {
      previewTarget.style.backgroundColor = color
    })
  }
}
