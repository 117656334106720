import AnimEvent from "anim-event"
import { newInstance, FlowchartConnector, BlankEndpoint } from "@jsplumb/browser-ui"

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    "trigger",
    "dropzone",
    "parent",
    "branchInput",
    "removedInput",
  ]

  static values = {
    endId: { type: String, default: "end-connector" },
    branch: String,
    line: String,
    persisted: { type: Boolean, default: false },
    child: String,
    root: Boolean,
  }

  initialize() {
    this.onWindowSizeChange = this.onWindowSizeChange.bind(this)
    this.instance = newInstance({
      container: document.getElementById('drawing_parent'),
    })

    window.dispatchEvent(
      new CustomEvent("step:connected", {
        detail: {
          replaceWith: this.element.id,
          target: this.lineValue,
          branch: this.branchValue || "branchless",
          persisted: this.persistedValue,
        },
      })
    )

    window.dispatchEvent(
      new CustomEvent("step:becomes-parent", {
        detail: {
          target: this.childValue,
          parentId: this.element.id,
        },
      })
    )
  }

  connect() {
    super.connect()
    if(!this.areWeTestingWithJest) {
      window.addEventListener("resize", AnimEvent.add(this.onWindowSizeChange))
    }

    this.notifyOtherStepsToRepositionLines()
  }

  disconnect() {
    AnimEvent.remove(this.onWindowSizeChange)
    window.removeEventListener("resize", this.onWindowSizeChange)

    window.dispatchEvent(
      new CustomEvent("step:removed", {
        detail: {
          target: this.lineValue,
          branch: this.branchValue,
          child: this.childValue,
          parent: this.hasParentTarget ? this.parentTarget.value : "",
          kind: this.kind
        },
        bubbles: true,
      })
    )

    super.disconnect()
  }

  updateParentReference({ detail }) {
    if (detail.target === this.element.id) {
      this.parentTarget.value = this.lineValue = detail.parentId
    }
  }

  togglePane() {
    if (window.paneId) {
      window.dispatchEvent(
        new CustomEvent("pane:alert", { detail: this.element.id })
      )
    } else {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("step:toggle", {
            bubbles: true,
            cancelable: true,
            detail: `${this.element.id}_pane`,
          })
        )

        this.triggerTarget.classList.toggle("halo--active")
      }, 0)
    }
  }

  showPane({ detail }) {
    if (detail === this.element.id) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("step:show", {
            bubbles: true,
            cancelable: true,
            detail: `${this.element.id}_pane`,
          })
        )
        this.triggerTarget.classList.add("halo--active")
      }, 1)
    }
  }

  hidePane(e) {
    if (
      e?.target !== this.triggerTarget &&
      document
        .getElementById(`${this.element.id}_pane`)
        ?.contains(e?.target) === false
    ) {
      window.dispatchEvent(
        new CustomEvent("step:hide", {
          bubbles: true,
          cancelable: true,
          detail: `${this.element.id}_pane`,
        })
      )
      this.triggerTarget.classList.remove("halo--active")
    }
  }

  notifyOtherStepsToRepositionLines() {
    window.dispatchEvent(new CustomEvent("step:reposition-line"))
  }

  draw(
    from,
    to,
    { fromOptions, toOptions, lineOptions = this.flowchartConnectorLineOptions }
  ) {
    if(this.areWeTestingWithJest) return

    const start = this.instance.addEndpoint(from, fromOptions)

    const end = this.instance.addEndpoint(to, toOptions)

    return this.instance.connect({
      source: start,
      target: end,
      connector: lineOptions,
    })
  }

  blankEndpoint(anchor) {
    let object = {
      endpoint: BlankEndpoint.type,
    }

    if (anchor) {
      object.anchor = anchor
    }

    return object
  }

  get hasChildStep() {
    return this.stepTypes.includes(
      document.getElementById(this.childValue)?.dataset.controller
    )
  }

  get stepTypes() {
    return ["step--condition", "step--wait", "step--message", "automation--question", "automation--property"]
  }

  get flowchartConnectorLineOptions() {
    return {
      type: FlowchartConnector.type,
      options: {
        cornerRadius: 10,
        stub: [1, 0],
        midpoint: 0.1,
        alwaysRespectStubs: true,
      },
    }
  }

  get inBranch() {
    return this.branchValue === "in_branch"
  }

  get insideEdgeBranch() {
    return this.element.closest("[data-controller='step--branch']")
      ?.dataset["step-BranchEdgeValue"] === "true"
  }

  get areWeTestingWithJest() {
    const isBrowser = typeof process === 'undefined' || process.type === 'renderer' || process.browser === true || process.__nwjs

    if(isBrowser) {
      return false
    } else {
      return process.env.JEST_WORKER_ID !== undefined;
    }
  }

  get isLastStep() {
    return (
      this.branchValue === "branchless" && !this.childValue && !this.rootValue
    )
  }
}
