import {get, post} from "@rails/request.js";

import TagsController from "./tags_controller"

export default class extends TagsController {
  static values = {
    key: { type: String, default: "list" }
  }

  static targets = ["list", "attachedList"]

  async save({currentTarget: label}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        id: label.dataset.value,
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    const url = this.persistedValue ? this.labelingUrlValue : this.urlValue
    post(url, options)
  }

  async saveNewList({currentTarget: newList}) {
    const options = {
      responseKind: "turbo-stream",
      body: {
        [this.keyValue]: { name: newList.dataset.value },
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    await post(this.urlValue, options)

    this.menuTarget.innerHTML = ''
  }

  removeItem(e) {
    super.removeItem(e, '[data-property--list-target="item"]')
  }

  resetTargetConnected() {
    this.inputTarget.value = ""
    this.inputTarget.focus()

    if(this.hasResetTarget) {
      this.resetTarget.remove()
    }
  }

  async onInputEnter() {
    const options = {
      responseKind: "turbo-stream",
      body: {
        [this.keyValue]: { name: this.inputTarget.value },
        mode: this.element.closest('[data-mode]')?.dataset.mode
      }
    }

    await post(this.urlValue, options)
    this.refocus()
  }
}
