import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  initialize() {
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)

    super.initialize()
  }

  connect() {
    this.element.addEventListener('mousedown', this.onMouseDown)

    super.connect()
  }

  disconnect() {
    this.element.removeEventListener('mousedown', this.onMouseDown)

    super.disconnect()
  }

  onMouseDown(event) {
    this.offsetX = event.clientX - this.element.getBoundingClientRect().left
    this.offsetY = event.clientY - this.element.getBoundingClientRect().top

    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove(event) {
    if (this.offsetX && this.offsetY) {
      this.element.classList.add('select-none')

      this.element.style.left = `${event.clientX - this.offsetX}px`
      this.element.style.top = `${event.clientY - this.offsetY}px`
    }
  }

  onMouseUp() {
    this.element.classList.remove('select-none')

    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)

    this.offsetX = null
    this.offsetY = null
  }
}
