import LineController from "./line_controller"
import { StraightConnector, BlankEndpoint } from "@jsplumb/browser-ui"

export default class extends LineController {
  static values = {
    showPane: { type: Boolean, default: true },
    changed: { type: Boolean, default: false },
    name: String,
    triggerId: String,
  }
  static targets = [
    "draggable",
    "trigger",
    "dropzone",
    "triggerInput",
    "triggerKindInput",
    "openModalButton",
    "anyChannelCheckbox",
    "channelsConflictIcon",
    "saveChangesButton",
    "submitButton",
    'canvas',
    "pane",
  ]

  initialize() {
    this.changedNameValue = this.nameValue

    this.isPanning = false
    this.startX = 0
    this.startY = 0
    this.translateX = 0
    this.translateY = 0

    this.velocityX = 0
    this.velocityY = 0
    this.momentumInterval = null

    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
    this.paneObserver = new IntersectionObserver(this.intersectionObserverCallback, {
      root: null,
      threshold: 0.1,
    })

    this.onMouseWheel = this.onMouseWheel.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)

    super.initialize()
  }

  connect() {
    this.element.addEventListener('wheel', this.onMouseWheel)
    this.element.addEventListener('mouseup', this.onMouseUp)

    this.element.addEventListener('mouseleave', this.onMouseLeave)
    this.element.addEventListener('mousedown', this.onMouseDown)

    this.element.addEventListener('mousemove', this.onMouseMove)

    this.initialLine = this.draw(document.getElementById("start_label"), document.getElementById("end_label"), this.defaultDrawOptions)

    if (this.showPaneValue) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("step:toggle", {
            bubbles: true,
            cancelable: true,
            detail: "journeys--editor_pane",
          })
        )
        this.triggerTarget.classList.add("halo--active")
      }, 1)
    }

    window.triggerId = this.triggerIdValue

    if (!this.showPaneValue) {
      // when the journey is being edited
      // steps are added and connected to each other via JS. Which sets this.changedValue to true
      // although the journey hasn't changed.
      // Wait until all steps are connected to each other and set this.changedValue back to false
      setTimeout(() => {
        this.changedValue = false
      }, 1)
    }

    super.connect()
  }

  disconnect() {
    this.element.removeEventListener('wheel', this.onMouseWheel)
    this.element.removeEventListener('mouseup', this.onMouseUp)

    this.element.removeEventListener('mouseleave', this.onMouseLeave)
    this.element.removeEventListener('mousedown', this.onMouseDown)

    this.element.removeEventListener('mousemove', this.onMouseMove)

    this.instance.deleteConnection(this.initialLine)
  }

  onMouseWheel(e) {
    if (this.disabledPanning) return

    e.preventDefault()

    this.translateX -= e.deltaX
    this.translateY -= e.deltaY

    this.canvasTarget.style.transform = `translate3D(${this.translateX}px, ${this.translateY}px, 0)`
  }

  onMouseUp() {
    if (this.disabledPanning) return

    this.isPanning = false

    this.element.classList.remove('cursor-grabbing')
    this.element.classList.add('cursor-grab')

    const applyMomentum = () => {
      this.translateX += this.velocityX
      this.translateY += this.velocityY

      this.canvasTarget.style.transform = `translate3D(${this.translateX}px, ${this.translateY}px, 0)`

      this.velocityX *= 0.9
      this.velocityY *= 0.9

      if (Math.abs(this.velocityX) >= 0.1 || Math.abs(this.velocityY) >= 0.1) {
        requestAnimationFrame(applyMomentum)
      }
    }

    requestAnimationFrame(applyMomentum)
  }

  onMouseLeave() {
    if(this.disabledPanning) return

    this.isPanning = false

    this.element.classList.remove('cursor-grabbing')
    this.element.classList.add('cursor-grab')
  }

  onMouseDown({ clientX, clientY }) {
    if(this.disabledPanning) return

    this.isPanning = true
    this.startX = clientX - this.translateX
    this.startY = clientY - this.translateY

    this.element.classList.remove('cursor-grab')
    this.element.classList.add('cursor-grabbing')
  }

  onMouseMove({ movementX, movementY }) {
    if (!this.isPanning || this.disabledPanning) return

    const deltaX = movementX
    const deltaY = movementY

    this.velocityX = deltaX
    this.velocityY = deltaY

    this.translateX += deltaX
    this.translateY += deltaY

    this.canvasTarget.style.transform = `translate3D(${this.translateX}px, ${this.translateY}px, 0)`
  }

  intersectionObserverCallback(entries) {
    const anyIntersecting = entries.some(entry => entry.isIntersecting)

    if (anyIntersecting) {
      this.disablePanning()
    } else {
      this.enablePanning()
    }
  }

  disablePanning() {
    this.disabledPanning = true
    this.element.classList.remove('cursor-grab')
  }

  enablePanning() {
    this.disabledPanning = false
    this.element.classList.add('cursor-grab')
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  redrawLine({ detail }) {
    this.changedValue = true

    this.submitButtonTarget.disabled = document.querySelectorAll("[data-kind]").length === 0 && this.allStepsAreValid

    if (detail.target !== this.element.id) return
    this.instance.deleteConnection(this.initialLine)

    this.initialLine = this.draw(
      document.getElementById("start_label"),
      document.getElementById(detail.replaceWith),
      this.defaultDrawOptions
    )

    this.dropzoneTarget.setAttribute("data-child", detail.replaceWith)
  }

  repositionLines() {
    this.instance.repaintEverything()
  }

  reactivateSubmitButtons() {
    if (this.allStepsAreValid && window.triggerId) {
      document
        .querySelectorAll("input[type='submit']")
        .forEach((submitInput) => {
          submitInput.disabled = false
        })
    }
    this.submitButtonTarget.disabled =
      document.querySelectorAll("[data-step]").length === 0 &&
      this.allStepsAreValid
    this.changedValue = false
  }

  removeActiveClass() {
    setTimeout(() => {
      this.triggerTarget.classList.remove("halo--active")
    }, 0)
  }

  showDropzone({ detail }) {
    this.changedValue = true

    if (detail.target === this.element.id || detail.parent === this.element.id) {
      this.instance.deleteConnection(this.initialLine)
      this.initialLine = this.draw(
        document.getElementById("start_label"),
        document.getElementById(detail.child || "end_label"),
        this.defaultDrawOptions
      )

      this.instance.repaintEverything()
      this.dropzoneTarget.setAttribute("data-child", detail.child)
      setTimeout(() => this.instance.repaintEverything(), 0)
    }
    this.submitButtonTarget.disabled =
      document.querySelectorAll("[data-step]").length === 0 &&
      this.allStepsAreValid
  }

  alertUserAboutUnsavedChanges(e) {
    if(window.paneId) {
      window.dispatchEvent(
        new CustomEvent("pane:alert", { detail: this.element.id })
      )

      return
    }


    if (this.changedValue || this.changedNameValue !== this.nameValue) {
      this.openModalButtonTarget.click()
      e.preventDefault()
    }
  }

  setUnsavedChangesToFalse() {
    this.changedValue = false
  }

  showConflictIcon() {
    this.channelsConflictIconTarget.classList.remove("hidden")
  }

  hideConflictIcon() {
    if (document.querySelectorAll("[data-conflict]").length === 0) {
      this.channelsConflictIconTarget.classList.add("hidden")
    }
  }

  setTrigger({ dataset }) {
    this.dispatch("trigger:changed", {
      detail: dataset.trigger,
      target: document.documentElement,
    })

    if (dataset.anyChannel === "true") {
      window.triggerIsChannel = true
      this.anyChannelCheckboxTarget.checked = true
    } else {
      window.triggerIsChannel = false
      this.anyChannelCheckboxTarget.checked = false
    }

    if (dataset.journeyChannels === "true") {
      window.triggerIsChannel = true
      this.triggerKindInputTarget.checked = true
      this.anyChannelCheckboxTarget.checked = false
    } else {
      this.triggerKindInputTarget.checked = false
    }

    window.triggerId = dataset.trigger

    if (window.triggerId) {
      this.saveChangesButtonTarget.disabled = false

      if (
        document.querySelectorAll("[data-step]").length > 1 &&
        this.allStepsAreValid
      ) {
        this.submitButtonTarget.disabled = false
      }
    }

    setTimeout(() => {
      if (this.invalidStepPresent) {
        document
          .querySelectorAll("input[type='submit']")
          .forEach((submitInput) => {
            submitInput.disabled = true
          })
      }
    }, 500) // sync event bus for event management
    this.changedValue = true
  }

  // happens when journey name is changed without errors
  setNameValue(e) {
    this.changedNameValue = e.dataset.name
  }

  resetNameToDefaultValue() {
    this.changedNameValue = this.nameValue
  }

  paneTargetConnected(item) {
    this.paneObserver.observe(item)
  }

  paneTargetDisconnected(item) {
    this.paneObserver.unobserve(item)
  }

  // private

  get defaultDrawOptions() {
    return {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Top"),
      lineOptions: StraightConnector.type,
    }
  }

  get invalidStepPresent() {
    return document.querySelectorAll("[data-invalid]").length > 0
  }

  get allStepsAreValid() {
    return !this.invalidStepPresent
  }
}
